var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Popup Map"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePopup) + " ")];
      },
      proxy: true
    }])
  }, [_c('l-map', {
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url
    }
  }), _c('l-marker', {
    attrs: {
      "lat-lng": _vm.markerLatLng
    }
  }, [_c('l-popup', [_vm._v("You're here!")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }