var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Layer Group"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLayerGroup) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.fitPolyline
    }
  }, [_vm._v(" Fit map to polyline ")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Contol Layers position: "
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.Positions
    },
    model: {
      value: _vm.layersPosition,
      callback: function callback($$v) {
        _vm.layersPosition = $$v;
      },
      expression: "layersPosition"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Attribution position:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.Positions
    },
    model: {
      value: _vm.attributionPosition,
      callback: function callback($$v) {
        _vm.attributionPosition = $$v;
      },
      expression: "attributionPosition"
    }
  })], 1)], 1)], 1)], 1), _c('l-map', {
    staticStyle: {
      "height": "500px",
      "width": "100%"
    },
    attrs: {
      "zoom": _vm.zoom,
      "options": _vm.mapOptions,
      "center": _vm.center,
      "bounds": _vm.bounds,
      "min-zoom": _vm.minZoom,
      "max-zoom": _vm.maxZoom
    },
    on: {
      "update:zoom": function updateZoom($event) {
        _vm.zoom = $event;
      }
    }
  }, [_c('l-control-layers', {
    attrs: {
      "position": _vm.layersPosition,
      "collapsed": false,
      "sort-layers": true
    }
  }), _vm._l(_vm.tileProviders, function (tileProvider) {
    return _c('l-tile-layer', {
      key: tileProvider.name,
      attrs: {
        "name": tileProvider.name,
        "visible": tileProvider.visible,
        "url": tileProvider.url,
        "attribution": tileProvider.attribution,
        "token": _vm.token,
        "layer-type": "base"
      }
    });
  }), _c('l-control-zoom', {
    attrs: {
      "position": _vm.zoomPosition
    }
  }), _c('l-control-attribution', {
    attrs: {
      "position": _vm.attributionPosition,
      "prefix": _vm.attributionPrefix
    }
  }), _c('l-control-scale', {
    attrs: {
      "imperial": _vm.imperial
    }
  }), _vm._l(_vm.markers, function (marker) {
    return _c('l-marker', {
      key: marker.id,
      attrs: {
        "visible": marker.visible,
        "draggable": marker.draggable,
        "lat-lng": marker.position,
        "icon": marker.icon
      },
      on: {
        "update:latLng": function updateLatLng($event) {
          return _vm.$set(marker, "position", $event);
        },
        "update:lat-lng": function updateLatLng($event) {
          return _vm.$set(marker, "position", $event);
        },
        "click": function click($event) {
          return _vm.alert(marker);
        }
      }
    }, [_c('l-popup', {
      attrs: {
        "content": marker.tooltip
      }
    }), _c('l-tooltip', {
      attrs: {
        "content": marker.tooltip
      }
    })], 1);
  }), _c('l-layer-group', {
    attrs: {
      "layer-type": "overlay",
      "name": "Layer polyline"
    }
  }, _vm._l(_vm.polylines, function (item) {
    return _c('l-polyline', {
      key: item.id,
      attrs: {
        "lat-lngs": item.points,
        "visible": item.visible
      },
      on: {
        "click": function click($event) {
          return _vm.alert(item);
        }
      }
    });
  }), 1), _vm._l(_vm.stuff, function (item) {
    return _c('l-layer-group', {
      key: item.id,
      attrs: {
        "visible": item.visible,
        "layer-type": "overlay",
        "name": "Layer 1"
      },
      on: {
        "update:visible": function updateVisible($event) {
          return _vm.$set(item, "visible", $event);
        }
      }
    }, [_c('l-layer-group', {
      attrs: {
        "visible": item.markersVisible
      }
    }, _vm._l(item.markers, function (marker) {
      return _c('l-marker', {
        key: marker.id,
        attrs: {
          "visible": marker.visible,
          "draggable": marker.draggable,
          "lat-lng": marker.position
        },
        on: {
          "click": function click($event) {
            return _vm.alert(marker);
          }
        }
      });
    }), 1), _c('l-polyline', {
      attrs: {
        "lat-lngs": item.polyline.points,
        "visible": item.polyline.visible
      },
      on: {
        "click": function click($event) {
          return _vm.alert(item.polyline);
        }
      }
    })], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }